import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useMemo } from "react"
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"

import styles from "./header.module.css"

import twitterIcon from "../../images/twitter-icon.png"
import youtubeIcon from "../../images/youtube-icon.png"

const Header = ({ siteTitle, headerImage }) => {
  const imageData = useStaticQuery(graphql`
    query {
      allFile(filter: { internal: { mediaType: { regex: "images/" } } }) {
        edges {
          node {
            relativePath
            childImageSharp {
              fluid(quality: 100, maxWidth: 4160) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  `)

  const match = useMemo(
    () =>
      imageData.allFile.edges.find(
        ({ node }) => headerImage === node.relativePath
      ),
    [imageData, headerImage]
  )

  return (
    <BackgroundImage
      fluid={match.node.childImageSharp.fluid}
      alt="Header image"
    >
      <header className={styles.headerContainer}>
        <h1 className={styles.headerText}>
          <Link
            to="/"
            style={{
              textDecoration: `none`,
            }}
          >
            {siteTitle}
          </Link>
        </h1>
        <div className={styles.socialContainer}>
        <a className={styles.socialLink} style={{right:"55px"}} href="https://twitter.com/_devduck" target="_blank" rel="noopener noreferrer">
          <img src={twitterIcon} alt="Twitter icon"/>
        </a>
        <a className={styles.socialLink} href="https://youtube.com/c/devduck" target="_blank" rel="noopener noreferrer">
          <img src={youtubeIcon} alt="Youtube icon"/>
        </a>
        </div>
      </header>
    </BackgroundImage>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
  headerImage: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: "",
  headerImage: "meadow.png",
}

export default Header
