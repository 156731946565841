/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"

import Header from "../header/header"
import Navbar from "../navbar/navbar"
import styles from "./layout.module.css"

const Layout = ({ pageHeaderText, pageHeaderImage, children }) => {

  return (
    <>
      <Header siteTitle={pageHeaderText} headerImage={pageHeaderImage} />
      <Navbar/>
      <div className={styles.contentContainer}>
        <main>{children}</main>
        <footer>
          © DevDuck {new Date().getFullYear()}
        </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  pageHeader: PropTypes.string,
  pageHeaderImage: PropTypes.string,
  children: PropTypes.node.isRequired
}

export default Layout
