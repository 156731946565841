import { Link } from "gatsby"
import React from "react"

import styles from "./navbar.module.css"

const Navbar = () => {
  return (
    <div className={styles.navbarContainer}>
      <div className={styles.navbarContent}>
        <Link to="/">Home</Link>
        <Link to="/apps">Games & Apps</Link>
        <Link to="/about">About</Link>
        <Link to="/contact">Contact</Link>
      </div>
    </div>
  )
}

export default Navbar
